import React, { useState } from 'react';
import DebouncedInput from '../DebouncedInput';
import { Col, Form, Input, Modal, Row, Select, message } from 'antd';
import api from '../../service';

const MultiSimulatorStart = ({ id }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [data, setData] = useState({
    duration: 366666600,
    csmsUrl: 'ws://adapter:9000/ocpp16j',
    protocol: 'ocpp1.6',
    power: 22,
    efficiency: 1.0,
  });

  const update = async () => {
    if (data && (!data.csmsUrl || !data.csmsUrl))
      return message.error('Lütfen ocpp server adresi girin !');

    if (data && (!data.devicePrefix || !data.devicePrefix))
      return message.error('Lütfen cihaz id girin !');

    setConfirmLoading(true);

    await api
      .post(`/rest/simulator/v1/start-multiple`, data)
      .then(({ data }) => {
        if (data && data.result) {
          message.success('Simulator başarıyla oluşturuldu ...');
        } else {
          message.error('Simulator Oluşturulamadı');
        }
      })
      .catch((err) => {
        console.log(err);
        message.error('Simulator Oluşturulamadı !!!');
      });

    setTimeout(() => {
      setIsModalOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };

  return (
    <div>
      <style>
        {`
          .ant-modal-footer > button:first-child {
              display: inline-block !important;
          }
        `}
      </style>
      <button
        type="button"
        className="btn btn-success me-3 btn-lg"
        size={'large'}
        onClick={() => setIsModalOpen(true)}
      >
        <i className="fa-solid fa-charging-station"></i>
        Çoklu Simulator Başlat
      </button>
      <Modal
        title="Çoklu Simulator Başlat"
        open={isModalOpen}
        okText="Başlat"
        cancelText="Vazgeç"
        confirmLoading={confirmLoading}
        onOk={() => update()}
        onCancel={() => setIsModalOpen(false)}
      >
        <Form
          layout="horizontal"
          size={'large'}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 22,
          }}
          onFinish={update}
        >
          <Row direction="row">
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item
                label="Cihaz Id Ön Ek"
                name="devicePrefix"
                rules={[
                  {
                    required: true,
                    message: 'Lütfen cihaz id girin !',
                  },
                ]}
              >
                <DebouncedInput
                  delay={150}
                  name="devicePrefix"
                  record={data}
                  type="text"
                  setRecord={setData}
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item
                label="Simulator Aralığı"
                name="numbers"
                rules={[
                  {
                    required: true,
                    message: 'Lütfen cihaz id girin !',
                  },
                ]}
              >
                <Input.Group size="large">
                  <Row gutter={24}>
                    <Col span={12}>
                      <Form.Item name="data.startNumber">
                        <Input
                          placeholder="Başlangıç Numarası"
                          onChange={(e) =>
                            setData({
                              ...data,
                              startNumber: e.target.value,
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="data.endNumber">
                        <Input
                          placeholder="Başlangıç Numarası"
                          onChange={(e) =>
                            setData({
                              ...data,
                              endNumber: e.target.value,
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Input.Group>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item label="Cihaz Güç (kW) " name="power">
                <DebouncedInput
                  delay={150}
                  name="power"
                  record={data}
                  type="number"
                  setRecord={setData}
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item label="Ocpp Sürüm" name="protocol">
                <Select
                  defaultValue="ocpp16j"
                  style={{
                    width: 120,
                  }}
                  onChange={(v) => setData({ ...data, protocol: v })}
                  options={[
                    {
                      value: 'ocpp1.6',
                      label: 'OCPP 1.6',
                    },
                    {
                      value: 'ocpp2.0.1',
                      label: 'OCPP 2.0.1',
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item
                label="Bağlantı Yapılacak Server Adresi"
                name="csmsUrl"
                rules={[
                  {
                    required: true,
                    message: 'Lütfen adres giriniz !',
                  },
                ]}
              >
                <DebouncedInput
                  delay={150}
                  name="csmsUrl"
                  record={data}
                  type="text"
                  setRecord={setData}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default React.memo(MultiSimulatorStart);
