import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import api from '../service/index';
import qs from 'qs';

export default (props) => {
  let { record, setRecord, name, mode, disabled, languagesCms, size } = props;
  const [cities, setCities] = useState([]);
  let _params = {
    sort: '-createdAt',
    page: 1,
    pageSize: 100,
    country_id: (record && record.countryId) || '43',
  };

  useEffect(() => {
    api.get(`/rest/city?${qs.stringify(_params)}`).then(({ data }) => {
      if (data && data.result) {
        let dbCities = data.result.sort((a, b) => a.name.localeCompare(b.name));
        setCities(dbCities);
      }
    });
  }, [record]);

  return (
    <Select
      size={size ? size : 'large'}
      value={(record && record[name]) || []}
      showSearch={true}
      mode={mode}
      placeholder={languagesCms.SELECT_CITY}
      onChange={(v) => setRecord({ ...record, [name]: v, districtId: null })}
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={disabled || false}
    >
      {cities &&
        cities.map((b) => (
          <Select.Option
            key={b.name}
            value={b._id}
          >
            {b.name}
          </Select.Option>
        ))}
    </Select>
  );
};
