import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Layout, Menu, Popconfirm } from 'antd';
import { useGlobal } from 'reactn';
import { useSelector } from 'react-redux';
import { env } from '../app.config';
import { FileSearchOutlined, SettingOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';

const { Sider } = Layout;

const SideMenu = () => {
  const [collapsed, setCollapsed] = useGlobal('collapsed');
  let [modules] = useGlobal('modules');
  let { alert } = useSelector((state) => state.alert);
  const [user] = useGlobal('user');
  const [, setToken] = useGlobal('token');
  const [, setModules] = useGlobal('modules');
  const [, setLangCode] = useGlobal('langCode');
  const [langCms] = useGlobal('langCms');

  document.title = env.TABTITLE;

  const getMenu = () => {
    if (modules) {
      function getItem(label, key, icon, children, type) {
        return {
          key,
          icon,
          children,
          label,
          type,
        };
      }

      const items = [
        getItem('Kullanıcılar', '1', <i className="fa-solid fa-users"></i>, ),
      ];

      return items

      // const icons = [
      //   {
      //     name: 'user',
      //     icon: 'fa-regular fa-user',
      //   },
      //   {
      //     name: 'call-center',
      //     icon: 'fa-solid fa-phone-volume',
      //   },
      //   {
      //     name: 'reports',
      //     icon: 'fa-solid fa-clipboard',
      //   },
      //   {
      //     name: 'cpo',
      //     icon: 'fa-solid fa-bars-progress',
      //   },
      //   {
      //     name: 'price',
      //     icon: 'fa-solid fa-turkish-lira-sign',
      //   },
      //   {
      //     name: 'chargepoints',
      //     icon: 'fa-solid fa-charging-station',
      //   },
      //   {
      //     name: 'dealer',
      //     icon: 'fa-solid fa-layer-group',
      //   },
      //   {
      //     name: 'monitoring',
      //     icon: 'fa-solid fa-battery-three-quarters',
      //   },
      //   {
      //     name: 'logs',
      //     icon: 'fa-solid fa-asterisk',
      //   },
      //   {
      //     name: 'app-management',
      //     icon: 'fa-solid fa-gears',
      //   },
      //   {
      //     name: 'reservation',
      //     icon: 'fa-regular fa-calendar-check',
      //   },
      //   {
      //     name: 'epdk',
      //     icon: 'fa-solid fa-code-branch',
      //   },
      // ];

      // const arr = [];
      // const arrGroups = [];
      // const groupNames = [];

      // // diğer moduller
      // const arrOther = [];

      // for (const item of modules) {
      //   if (item.groupName) groupNames.push(item.group);
      //   else arrOther.push(item);
      // }
      // const groupNameSet = new Set(groupNames);

      // for (const groupName of groupNameSet) {
      //   arrGroups.push({
      //     groupName,
      //     groupNameFilter: modules.filter((e) => e.group === groupName),
      //   });
      // }

      // // grup olmayanları döner
      // for (const item of arrOther) {
      //   arr.push(
      //     getItem(
      //       <Link
      //         style={{
      //           fontWeight: '700',
      //         }}
      //         to={'/' + item.type}
      //       >
      //         <span className="text-white">{item.name}</span>
      //       </Link>,
      //       item.order,
      //       <i
      //         style={{ fontSize: '1.3rem', color: 'white' }}
      //         className={item && item.icon}
      //       ></i>,
      //     ),
      //   );
      // }

      // // gruplandırma
      // for (const [index, item] of arrGroups.entries()) {
      //   const arrSub = [];

      //   // grup başlık
      //   let groutTitle;

      //   // grup olanların alt elemanlarını döner
      //   for (const subItem of item.groupNameFilter) {
      //     groutTitle = subItem.groupName;
      //     arrSub.push(
      //       getItem(
      //         <Link to={'/' + subItem.type}>
      //           <span className="">
      //             {' '}
      //             {subItem.subName ? subItem.subName : subItem.name}
      //           </span>
      //         </Link>,
      //         subItem.order,
      //         <i>
      //           <i
      //             style={{ fontSize: '1.3rem', color: 'white' }}
      //             className={subItem && subItem.icon}
      //           ></i>
      //           {/* burada sadece alert modülü için sayı eklemesi yapıldı */}
      //           {subItem.type === 'alert' && alert !== 0 && (
      //             <>
      //               &nbsp;&nbsp; &nbsp;&nbsp;
      //               <i
      //                 style={{
      //                   color: 'red',
      //                   fontWeight: 'bold',
      //                 }}
      //                 className="fa-regular fa-bell"
      //               >
      //                 {' '}
      //                 &nbsp;{alert !== 0 && alert}
      //               </i>
      //             </>
      //           )}
      //         </i>,
      //       ),
      //     );
      //   }

      //   const findIcon =
      //     icons && icons.find((i) => i.name === (item && item.groupName));
      //   const selectIcon = (findIcon && findIcon.icon) || '';

      //   // grupları döner
      //   arr.push(
      //     getItem(
      //       groutTitle || '',
      //       index,
      //       <i
      //         style={{ fontSize: '1.3rem', marginRight: '7px' }}
      //         className={selectIcon}
      //       ></i>,
      //       arrSub,
      //     ),
      //   );
      // }

      // return arr;
    }
  };

  const logout = () => {
    Cookies.remove('token');
    window.localStorage.clear();
    setToken(null);
    setModules([]);
    setLangCode(langCms[0].code);
  };

  const settingMenu = (
    <Menu className="head-menu">
      {user && user.role && user.role <= 3 && (
        <Menu.Item>
          <Link to="/alert">Alert</Link>
        </Menu.Item>
      )}

      {user && user.role && user.role === 1 && (
        <>
          <Menu.Item>
            <Link to="/Logs">Log</Link>
          </Menu.Item>
          <Menu.Item>
            <Link to="/settings">Ayarlar</Link>
          </Menu.Item>
        </>
      )}
    </Menu>
  );

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={() => setCollapsed(!collapsed)}
      theme="dark"
      className="side-menu"
      width={collapsed ? '150' : '260'}
    >
      <>
        <div className="row d-flex py-2 align-items-center">
          {!collapsed ? (
            <>
              <div className="col-6">
                <div className="logo float-end">
                  <a href="/">
                    <img alt="" src={'/logo/logo.png'} />
                  </a>
                </div>
              </div>
              <div className="col-6 text-white font-bold">Yönetim Paneli</div>
            </>
          ) : (
            <>
              <div className="col-12">
                <div className="logo">
                  <a href="/">
                    <img alt="" src={'/logo/favicon_120.png'} />
                  </a>
                </div>
              </div>
            </>
          )}
        </div>

        <div
          style={{ borderBottom: '1px solid #64748b' }}
          className="mx-2"
        ></div>

        <div className="row mx-2" style={{ padding: '.5rem 5px' }}>
          {!collapsed ? (
            <>
              <div
                className="col-9"
                style={{
                  lineHeight: 'normal',
                  display: 'flex',
                  alignItems: 'center',
                  color: '#fff',
                }}
              >
                {user && user.name} {user && user.lastname}
                <br />
              </div>
              <div className="col-3">
                <Popconfirm
                  onConfirm={() => logout()}
                  title={'Çıkış Yap'}
                  okText={'Onayla'}
                  cancelText={'Vazgeç'}
                >
                  <i
                    style={{
                      cursor: 'pointer',
                    }}
                    className="fa-solid fa-arrow-right-from-bracket text-white float-end"
                  ></i>
                </Popconfirm>
              </div>
            </>
          ) : (
            <>
              <div className="col-12">
                <Popconfirm
                  onConfirm={() => logout()}
                  title={'Çıkış Yap'}
                  okText={'Onayla'}
                  cancelText={'Vazgeç'}
                >
                  <i
                    style={{
                      cursor: 'pointer',
                    }}
                    className="fa-solid fa-arrow-right-from-bracket text-white float-end"
                  ></i>
                </Popconfirm>
              </div>
            </>
          )}
        </div>
        <div
          style={{ borderBottom: '1px solid #64748b' }}
          className=" mx-2"
        ></div>

        <div className="row mx-2" style={{ padding: '.5rem .5rem' }}>
          {!collapsed ? (
            <>
              <div className="col-6">
                {user &&
                  user.role &&
                  [1, 2, 3, 5].includes(user.role) === true && (
                    <div>
                      <Link to="/alert">
                        <i
                          style={{ color: 'red' }}
                          className="fa-solid fa-bell"
                        >
                          {alert ? ' ' + alert : ''}
                        </i>
                      </Link>
                    </div>
                  )}
              </div>

              <div className="col-6">
                {user && user.role === 1 && (
                  <div className="row">
                    <div className="col-6">
                      <Link to="/Logs">
                        <FileSearchOutlined className="logs" />
                      </Link>
                    </div>
                    <div className="col-6">
                      <Link to="/settings">
                        <SettingOutlined className="settings" />
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <Dropdown overlay={settingMenu} placement="bottomRight" arrow>
                <i className="fa-solid fa-ellipsis font-bold"></i>
              </Dropdown>
            </>
          )}
        </div>
        {user && user.role && [1, 2, 3, 5, 6].includes(user.role) === true && (
          <div
            style={{ borderBottom: '1px solid #64748b' }}
            className="mx-2"
          ></div>
        )}

        <Menu
          // defaultSelectedKeys={['0']}
          // defaultOpenKeys={['0']}
          mode="inline"
          theme="dark"
          inlineCollapsed={collapsed}
          items={getMenu()}
          className="side-menu"
          style={{ height: 'calc(100vh - 26vh)', overflowY: 'auto' }}
          scrollbar={{
            style: { backgroundColor: '#f1f1f1' },
            thumbStyle: { backgroundColor: '#1890ff' },
          }}
        />
        {/* <div className="row">
        <button style={{
        }} className="btn btn-light w-25" >test</button>
      </div> */}
      </>
    </Sider>
  );
};

export default SideMenu;
