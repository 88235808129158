import React, { useState } from 'react';
import DebouncedInput from '../DebouncedInput';
import { Button, Col, Form, Modal, Row, message } from 'antd';
import api from '../../service';

const PasswordUpdateModal = ({ id }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [data, setData] = useState({});

  const update = async () => {
    if (data && (!data.password || !data.passwordConfirm))
      return message.error('Lütfen Şifre Bilgilerini eksiksiz girin !');

    if (data && data.password !== data.passwordConfirm)
      return message.error('Şifreleriniz Eşleşmiyor !!!');

    console.log(data);

    setConfirmLoading(true);

    await api
      .put('/rest/attendees/update-password/' + id, data)
      .then(async ({ data }) => {
        console.log(data);

        if (
          data &&
          data.result_message &&
          data.result_message &&
          data.result_message.type === 'success'
        ) {
          message.success(
            (data &&
              data.result_message &&
              data.result_message &&
              data.result_message.message) ||
              'Şifre Başarıyla Güncellendi',
          );
        } else {
          message.error(
            (data &&
              data.result_message &&
              data.result_message &&
              data.result_message.message) ||
              'Şifre Güncellenemedi !',
          );
        }

        return data;
      })
      .catch((err) => {
        console.log(err);
        message.success('Şifre Güncellenemedi !!!');
      });

    setData({});

    setTimeout(() => {
      setIsModalOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };

  return (
    <div>
      <style>
        {`
          .ant-modal-footer > button:first-child {
              display: inline-block !important;
          }
        `}
      </style>
      <Button
        className="btn btn-danger me-3"
        icon={<i className="fa-solid fa-lock"></i>}
        size={'large'}
        onClick={() => setIsModalOpen(true)}
      >
        Şifre Güncelle
      </Button>
      <Modal
        title="Şifre Güncelleme Ekranı"
        open={isModalOpen}
        okText="Güncelle"
        cancelText="Vazgeç"
        confirmLoading={confirmLoading}
        onOk={() => update()}
        onCancel={() => setIsModalOpen(false)}
      >
        <Form
          layout="horizontal"
          size={'large'}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 22,
          }}
          onFinish={update}
        >
          <Row direction="row">
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item
                label="Şifre"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Lütfen Şifre Giriniz !',
                  },
                ]}
              >
                <DebouncedInput
                  delay={150}
                  name="password"
                  record={data}
                  type="password"
                  setRecord={setData}
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item
                label="Şifre Onay"
                name="passwordConfirm"
                rules={[
                  {
                    required: true,
                    message: 'Lütfen Şifrenizi Tekrar Giriniz !',
                  },
                ]}
              >
                <DebouncedInput
                  delay={150}
                  name="passwordConfirm"
                  type="password"
                  record={data}
                  setRecord={setData}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default PasswordUpdateModal;
