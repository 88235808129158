import React, { useState, useEffect } from 'react';

const DebouncedInput = ({
  delay,
  record,
  setRecord,
  name,
  disabled,
  type,
}) => {
  const [value, setValue] = useState(record[name] ? record[name] : '');

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      setRecord({ ...record, [name]: value });
    }, delay);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [value]);

  const handleInputChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <div>
      <input
        type={type || 'text'}
        value={value}
        disabled={disabled}
        defaultValue={record && record[name]}
        onChange={handleInputChange}
        className="form-control"
      />
    </div>
  );
};

export default DebouncedInput;
