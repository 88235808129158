import React from 'react';
import { useGlobal } from 'reactn';
import { Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import api from './../service';
import Cookies from 'js-cookie';
import qs from 'qs';
import { setAuth } from '../stores/auth';
import { setAlert } from '../stores/alert';
import { useDispatch } from 'react-redux';
import { env } from "../app.config"

const Login = (props) => {
  let [langCms] = useGlobal('langCms');
  let [, setToken] = useGlobal('token');
  let [, setModules] = useGlobal('modules');
  let [, setLanguagesCms] = useGlobal('languagesCms');
  let [, setUser] = useGlobal('user');
  let [langCode] = useGlobal('langCode');
  const dispatch = useDispatch();

  document.title = env.TABTITLE;

  const auth = async ({ username, password }) => {
    let params = {
      email: username.trim(),
      password: password.trim(),
    };

    let query = {
      lang: langCode ? langCode : langCms ? langCms[0].code : 'TR',
      adminShow: 'true',
    };
    let queryLangCms = {
      langCode: langCode ? langCode : langCms ? langCms[0].code : 'TR',
    };

    delete api.headers['Authorization'];
    await api
      .post('/rest/login', params)
      .then(async ({ data }) => {
        setToken(data.result.token);
        setUser(data.result);
        localStorage.setItem('user', JSON.stringify(data.result));

        api.setHeader('Authorization', 'Bearer ' + data.result.token);

        Cookies.set('token', data.result.token);
        await api
          .get(`/rest/languagesCms?${qs.stringify(queryLangCms)}`)
          .then(({ data }) => {
            if (langCode) {
              data.result.rows.forEach((element) => {
                if (element._id === langCode) {
                  setLanguagesCms(element.items);
                  localStorage.setItem(
                    'languagesCms',
                    JSON.stringify(element.items),
                  );
                  props.history.push(element.type);
                }
              });
            } else {
              data.result.rows.forEach((element) => {
                if (element._id === langCms[0].code) {
                  setLanguagesCms(element.items);
                  localStorage.setItem(
                    'languagesCms',
                    JSON.stringify(element.items),
                  );
                  props.history.push(element.type);
                }
              });
            }
          });

        await api
          .get(`/rest/modules?${qs.stringify(query)}`)
          .then(({ data }) => {
            setModules(data.result.rows);
            localStorage.setItem('modules', JSON.stringify(data.result.rows));
            props.history.push(data.result.rows[0].type);
          });
      })
      .catch((e) => {
        alert('Password or Username is incorrect');
      })
      .finally(async () => {
        await api.get(`/rest/alert-count`).then(async ({ data }) => {
          if (data && data.result) {
            await dispatch(setAlert(data.result));
          }
        });

        await api.get(`/rest/authorizations`).then(async ({ data }) => {
          if (data && data.result) {
            await dispatch(setAuth(data.result));
          }
        });
      });
  };

  const onFinish = (values) => {
    auth(values);
  };

  return (
    <div className="login-wrap ">
      <div className="form ">
        <div
          className="form-back"
          style={{
            backgroundColor: '#fff',
            padding: '.5rem 5rem',
            borderRadius: '15px',
          }}
        >
          <div className="login-image d-flex justify-content-center align-items-center">
            <img alt="" src={env.CDN_HOST + "/icons/app/logo.png"} />
          </div>
          <Form
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              width: '100%',
              marginBottom: '20px',
            }}
            name="normal_login"
            className="login-form"
            size="large"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: 'Please input your Username!',
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Email"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your Password!',
                },
              ]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Şifre"
              />
            </Form.Item>
            {/* {langCms && langCms.length > 1 && (
            <Form.Item>
              <Select
                defaultValue={langCms[0].code}
                style={{ width: 120 }}
                onChange={(e) => handleMenuClick(e)}
              >
                {langCms.map((element, index) => (
                  <Option key={index} value={element.code}>
                    {element.code}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )} */}

            <Form.Item>
              <Button
                type="light"
                htmlType="submit"
                className="login-form-button"
                id="1"
                style={{
                  color: '#000',
                  borderColor: '#b2cf97',
                  marginBottom: '20px',
                }}
              >
                Giriş Yap
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
