import React, { useEffect, useGlobal } from 'reactn';
import { Route, Switch } from 'react-router-dom';
import { Layout } from 'antd';
import SideMenu from './layout/SideMenu';

import {
  RedirectComp,
  NotFoundPage,
  Attendee,
  AttendeeDetail,
  Dashboard,
} from './pages';

const { Content } = Layout;

const Admin = (props) => {
  let [token, setToken] = useGlobal('token');
  let [user] = useGlobal('user');
  let [isSmall, setIsSmall] = useGlobal('isSmall');
  let [collapsed, setCollapsed] = useGlobal('collapsed');

  useEffect(() => {
    window.onresize = () => {
      setIsSmall(window.innerWidth < 1024);
    };
  }, [setIsSmall, setToken]);
  useEffect(() => {
    if (isSmall) setCollapsed(true);
    else setCollapsed(false);
  }, [isSmall, setCollapsed]);

  if (!token || !user) {
    props.history.push('/login');
  }

  return (
    <Switch>
      <Route>
        <Layout>
          <SideMenu collapsed={collapsed} />
          <Layout className="site-layout" style={{ minHeight: '100vh' }}>
            <Content key="page" className="main-wrap">
              {token && (
                <>
                  <Switch>
                    <Route exact path="/" component={RedirectComp} />

                    <Route exact path="/attendees" component={Attendee} />
                    <Route
                      exact
                      path="/attendees/edit/:id"
                      component={AttendeeDetail}
                    />
                    <Route
                      exact
                      path="/attendees/add"
                      component={AttendeeDetail}
                    />
                    <Route exact path="/dashboard" component={Dashboard} />

                    <Route path="*" component={NotFoundPage} />
                  </Switch>
                </>
              )}
            </Content>
          </Layout>
        </Layout>
      </Route>
    </Switch>
  );
};

export default Admin;
