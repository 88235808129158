import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    deviceAdd: null,
};

const deviceAdd = createSlice({
  name: 'deviceAdd',
  initialState,
  reducers: {
    setDeviceAdd: (state, action) => {
      state.deviceAdd = action.payload;
    },
  },
});

export const { setDeviceAdd } = deviceAdd.actions;
export default deviceAdd.reducer;
