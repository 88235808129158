import React, { useEffect, useState, useGlobal } from 'reactn';
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Card,
  message,
  Select,
  Alert,
  Breadcrumb,
  DatePicker,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { CityPicker, FileInputSingle, PasswordUpdateModal } from '../components';
import api from '../service/index';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import CountryPicker from '../components/countryPicker';
import DistrictPicker from '../components/districtPicker';
import moment from 'moment';
import locale from 'antd/lib/date-picker/locale/tr_TR';

const { Option } = Select;

const AttendeeDetail = (props) => {
  let params = useParams();
  let history = useHistory();
  let id = params.id !== 'add' ? params.id : false;
  let [languagesCms] = useGlobal('languagesCms');
  let [langCms] = useGlobal('langCms');
  let [langCode] = useGlobal('langCode');
  const [authorizations] = useGlobal('authorizations');
  const [user] = useGlobal('user');

  let newRecord = {
    name: '',
    lastname: '',
    role: 7,
    phone: '',
    email: '',
    tc: '',
    lang: langCode ? langCode : langCms ? langCms[0].code : '',
    credit: '0.0',
    countryId: '43',
    cityId: '',
    filters: {
      ac_type_2: true,
      ccs2: true,
      chademo: true,
      unknown: true,
      suitable_for_charging: true,
      out_of_service: true,
      in_use: true,
    },
    districtId: '',
    stationId: '',
    postCode: '',
    address: '',
    vkn: '',
    vd: '',
    company: '',
    car: '',
    dateOfBirth: '',
    userGroup: 'guest',
    userType: 'individual',
    passportNo: '',
  };
  let [data, setData] = useState(id ? [] : newRecord);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, setValidationCheck] = useState(false);
  let [isSmall] = useGlobal('isSmall');

  let [modules] = useGlobal('modules');

  useEffect(() => {
    if (modules && id) {
      setLoading(true);
      api
        .get('/rest/attendees/' + id)
        .then(({ data: { result, result_message } }) => {
          delete result.password;
          setData(result);
          setLoading(false);
        });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};

    if (data.name === null || data.name.length === 0)
      errors.name = languagesCms.ENFORCED;

    if (data.lastname === null || data.lastname.length === 0)
      errors.lastname = languagesCms.ENFORCED;

    if (data.phone === null || data.phone.length === 0)
      errors.phone = languagesCms.ENFORCED;

    if (data.email === null || data.email.length === 0)
      errors.email = languagesCms.ENFORCED;

    if (data.districtId === null || data.districtId.length === 0)
      errors.districtId = languagesCms.ENFORCED;

    if (data.cityId === null || data.cityId.length === 0)
      errors.cityId = languagesCms.ENFORCED;

    if (data.dateOfBirth === null || data.dateOfBirth === '')
      errors.dateOfBirth = languagesCms.ENFORCED;

    if (
      data.address === null ||
      data.address === undefined ||
      data.address === ''
    )
      errors.address = languagesCms.ENFORCED;

    if (
      data &&
      data.userType === 'individual' &&
      (data.tc === null || data.tc.length !== 11)
    )
      errors.tc = languagesCms.ENFORCED;

    if (
      data.userType === 'corporate' &&
      (data.vd === null || (data && data.vd && data.vd.length === 0))
    )
      errors.vd = languagesCms.ENFORCED;

    if (
      data.userType === 'corporate' &&
      (data.company === null ||
        (data && data.company && data.company.length === 0))
    )
      errors.company = languagesCms.ENFORCED;

    if (
      data.userType === 'corporate' &&
      (data.vkn === null || (data && data.vkn && data.vkn.length === 0))
    )
      errors.vkn = languagesCms.ENFORCED;

    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  let save = async () => {
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        if (data.mspName) data.mspName = data.mspName.toString();
        if (data.phone && !data.phone.startsWith('+')) {
          data.phone = '+' + data.phone;
        }
        delete data.password;

        api
          .put('/rest/attendees/' + id, data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
              history.push('/attendees');
            } else message.error(result_message.message, 2);
          });
      } else {
        if (data.mspName) data.mspName = data.mspName.toString();
        if (data.phone && !data.phone.startsWith('+')) {
          data.phone = '+' + data.phone;
        }
        if (data.stationId === '') data.stationId = [];
        api
          .post('/rest/attendees', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
              history.push('/attendees');
            } else {
              message.error(result_message.message, 2);
            }
          });
      }
    }
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h3 style={{ color: '#fff', marginBottom: '8px' }}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <a
                  style={{ color: '#fff', fontSize: '18px' }}
                  href="/attendees"
                >
                  Son Kullanıcılar
                </a>
              </Breadcrumb.Item>
              <Breadcrumb.Item style={{ color: '#fff', fontSize: '18px' }}>
                {data.name + ' ' + data.lastname}
              </Breadcrumb.Item>
            </Breadcrumb>
          </h3>
        </div>

        <div className="list-buttons">
          {id && <PasswordUpdateModal id={data._id} />}
          <Link to="/attendees">
            <Button
              type="light"
              icon={<LeftOutlined />}
              size={'large'}
              style={{ fontSize: '14px', height: '100%' }}
            >
              {!isSmall && languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card
          title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}
          loading={loading}
        >
          <Form
            disabled={
              authorizations &&
              authorizations
                .find((a) => a.number === user.role)
                .restraintList.includes('edit')
            }
            layout="horizontal"
            size={'large'}
            onFinish={save}
          >
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={'Kullanıcı Türü'}
                  required
                  help={errors.userType}
                  validateStatus={errors.userType ? 'error' : 'success'}
                >
                  <Select
                    defaultValue={data.userType ? data.userType : ''}
                    style={{ width: 120 }}
                    onChange={(v) => {
                      setData({ ...data, userType: v });
                    }}
                  >
                    <Option value={'individual'}>Bireysel</Option>
                    <Option value={'corporate'}>Kurumsal</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={'Kullanıcı Grubu'}
                  required
                  help={errors.userGroup}
                  validateStatus={errors.userGroup ? 'error' : 'success'}
                >
                  <Select
                    defaultValue={data.userGroup ? data.userGroup : ''}
                    style={{ width: 120 }}
                    onChange={(v) => {
                      setData({ ...data, userGroup: v });
                    }}
                  >
                    <Option value={'guest'}>Guest</Option>
                    <Option value={'fleet'}>Fleet</Option>
                    <Option value={'loyalty'}>Loyalty</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Avatar"
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <FileInputSingle
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="avatar"
                  />
                  <Alert
                    message="Avatar 1024 x 1024 çözünürlüğünde olmalıdır."
                    banner
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.PHONE}
                  required
                  help={errors.phone}
                  validateStatus={errors.phone ? 'error' : 'success'}
                >
                  <PhoneInput
                    width={'100%'}
                    country={'tr'}
                    value={data.phone}
                    onChange={(e) => setData({ ...data, phone: e })}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.NAME}
                  required
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <Input
                    name="name"
                    value={data.name}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.LASTNAME}
                  required
                  help={errors.lastname}
                  validateStatus={errors.lastname ? 'error' : 'success'}
                >
                  <Input
                    name="lastname"
                    value={data.lastname}
                    onChange={(e) =>
                      setData({ ...data, lastname: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.EMAIL}
                  required
                  help={errors.email}
                  validateStatus={errors.email ? 'error' : 'success'}
                >
                  <Input
                    name="email"
                    value={data.email}
                    onChange={(e) =>
                      setData({ ...data, email: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
              {!id && (
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label={languagesCms.TC}
                    required={
                      data && data.userType && data.userType === 'individual'
                    }
                    name="tc"
                    rules={[
                      {
                        required: true,
                        message: 'Lütfen Bir Tc Numara Giriniz!',
                      },
                      {
                        max: 11,
                        message: 'Tc 11 karakter olarak girilmelidir !',
                      },
                      {
                        min: 11,
                        message: 'Tc 11 karakter olarak girilmelidir !',
                      },
                    ]}
                    help={errors.tc}
                    validateStatus={errors.tc ? 'error' : 'success'}
                  >
                    <Input
                      name="tc"
                      value={data.tc}
                      onChange={(e) => setData({ ...data, tc: e.target.value })}
                    />
                  </Form.Item>
                </Col>
              )}
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={'Doğum Tarihiniz'}
                  required
                  help={errors.dateOfBirth}
                  validateStatus={errors.dateOfBirth ? 'error' : 'success'}
                >
                  <DatePicker
                    locale={locale}
                    format="DD-MM-YYYY"
                    onChange={(date, dateString) => {
                      setData({ ...data, dateOfBirth: dateString });
                    }}
                    placeholder="Tarih"
                    defaultValue={
                      data &&
                      (data.dateOfBirth
                        ? moment(data.dateOfBirth, 'DD-MM-YYYY')
                        : moment(new Date(), 'DD-MM-YYYY'))
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.COUNTRY}
                  help={errors.countryId}
                  validateStatus={errors.countryId ? 'error' : 'success'}
                >
                  <CountryPicker
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="countryId"
                    mode="single"
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.CITY}
                  help={errors.cityId}
                  validateStatus={errors.cityId ? 'error' : 'success'}
                >
                  <CityPicker
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="cityId"
                    mode="single"
                    disabled={data.countryId ? false : true}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.DISTRICT}
                  help={errors.districtId}
                  validateStatus={errors.districtId ? 'error' : 'success'}
                >
                  <DistrictPicker
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="districtId"
                    mode="single"
                    disabled={data.cityId ? false : true}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.CAR}
                  help={errors.car}
                  validateStatus={errors.car ? 'error' : 'success'}
                >
                  <Input
                    name="car"
                    value={data.car}
                    onChange={(e) => setData({ ...data, car: e.target.value })}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.POST_CODE}
                  help={errors.postCode}
                  validateStatus={errors.postCode ? 'error' : 'success'}
                >
                  <Input
                    name="postCode"
                    value={data.postCode}
                    onChange={(e) =>
                      setData({ ...data, postCode: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
              {!id && (
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label={languagesCms.CREATE_NEW_PASSWORD}
                    help={errors.password}
                    validateStatus={errors.password ? 'error' : 'success'}
                  >
                    <Input
                      name="password"
                      onChange={(e) =>
                        setData({ ...data, password: e.target.value })
                      }
                    />
                    <br />
                    <small>{languagesCms.PASSWORD_LEAVE_BLANK}</small>
                    <br />
                    <small>{languagesCms.PASSMESSAGE}</small>
                  </Form.Item>
                </Col>
              )}

              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={'Vergi Dairesi'}
                  help={errors.vd}
                  validateStatus={errors.vd ? 'error' : 'success'}
                >
                  <Input
                    name="vd"
                    value={data.vd}
                    disabled={data.userType === 'individual'}
                    onChange={(e) => setData({ ...data, vd: e.target.value })}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={'Şirket Adı'}
                  help={errors.company}
                  validateStatus={errors.company ? 'error' : 'success'}
                >
                  <Input
                    name="vd"
                    disabled={data.userType === 'individual'}
                    value={data.company}
                    onChange={(e) =>
                      setData({ ...data, company: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.VKN}
                  help={errors.vkn}
                  validateStatus={errors.vkn ? 'error' : 'success'}
                >
                  <Input
                    name="vkn"
                    disabled={data.userType === 'individual'}
                    value={data.vkn}
                    onChange={(e) => setData({ ...data, vkn: e.target.value })}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item
                  label={languagesCms.ADRESS}
                  help={errors.address}
                  required
                  validateStatus={errors.address ? 'error' : 'success'}
                >
                  <Input.TextArea
                    name="address"
                    value={data.address}
                    onChange={(e) =>
                      setData({ ...data, address: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button
                    style={{
                      backgroundColor: '#9eb23b',
                      borderColor: 'transparent',
                    }}
                    type="primary"
                    disabled={loading}
                    htmlType="submit"
                    size="large"
                    block
                  >
                    {' '}
                    {languagesCms.SAVE}{' '}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};
export default AttendeeDetail;
