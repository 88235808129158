import { configureStore } from '@reduxjs/toolkit';

import auth from './auth';
import alert from './alert';
import deviceAdd from './deviceAdd';

const store = configureStore({
  reducer: {
    auth,
    alert,
    deviceAdd
  },
});

export default store;
