import React, { useState } from 'react';
import { Card, Col, Row, Space, Statistic } from 'antd';
import { ArrowUpOutlined } from '@ant-design/icons';
import { StartSimulatorModal } from '../components';
import StopSimulatorModal from '../components/simulator/stopSimulator';
import MultiSimulatorStart from '../components/simulator/multiSimulatorStart';
import StopMultipleSimulator from '../components/simulator/stopMultipleSimulator';
import CreateCar from '../components/car/createCar';
import CarConnection from '../components/car/carConnection';
import DisconnectCar from '../components/car/disconnectCar';
import SetError from '../components/car/setError';

const Dashboard = () => {
  // const [data, setData] = useState([]);
  const [loading] = useState(false);

  // const [user] = useGlobal('user');

  // const get = async (page, filters, sorter) => {
  //   await api.get('/rest/dashboard').then(({ data }) => {
  //     if (data && data.result) {
  //       setData(data.result);
  //     }
  //   });
  //   setLoading(false);
  // };

  // useEffect(() => {
  //   get();
  // }, []);

  return (
    <div style={{ marginTop: '4.1rem', marginLeft: '1.5rem' }}>
      <div className="table-wrap">
        <Row direction="row" style={{ fontFamily: 'Ariel', fontWeight: '700' }}>
          <Col
            xs={{ span: 24 }}
            md={{ span: 8 }}
            style={{ marginBottom: '5px' }}
          >
            <Card
              loading={loading}
              style={{
                width: '100%',
                maxHeight: '265px',
                borderRadius: '11px',
                textAlign: 'center',
              }}
            >
              <h5 style={{ fontWeight: '500' }}>Aktif Çalışan Simulator</h5>
              <Space
                wrap
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '1rem',
                }}
              >
                <Statistic
                  value={11}
                  valueStyle={{
                    color: '#3f8600',
                    fontWeight: 'medium',
                    fontSize: '4.5rem',
                  }}
                  prefix={<ArrowUpOutlined />}
                />
              </Space>
            </Card>
          </Col>
          <Col
            xs={{ span: 24 }}
            md={{ span: 8 }}
            style={{ marginBottom: '5px' }}
          >
            <Card
              loading={loading}
              style={{
                width: '100%',
                maxHeight: '265px',
                borderRadius: '11px',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <StartSimulatorModal />
            </Card>
          </Col>
          <Col
            xs={{ span: 24 }}
            md={{ span: 8 }}
            style={{ marginBottom: '5px' }}
          >
            <Card
              loading={loading}
              style={{
                width: '100%',
                maxHeight: '265px',
                borderRadius: '11px',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <StopSimulatorModal />
            </Card>
          </Col>
          <Col
            xs={{ span: 24 }}
            md={{ span: 8 }}
            style={{ marginBottom: '5px' }}
          >
            <Card
              loading={loading}
              style={{
                width: '100%',
                maxHeight: '265px',
                borderRadius: '11px',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CreateCar />
            </Card>
          </Col>
          <Col
            xs={{ span: 24 }}
            md={{ span: 8 }}
            style={{ marginBottom: '5px' }}
          >
            <Card
              loading={loading}
              style={{
                width: '100%',
                maxHeight: '265px',
                borderRadius: '11px',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CarConnection />
            </Card>
          </Col>
          <Col
            xs={{ span: 24 }}
            md={{ span: 8 }}
            style={{ marginBottom: '5px' }}
          >
            <Card
              loading={loading}
              style={{
                width: '100%',
                maxHeight: '265px',
                borderRadius: '11px',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CarConnection />
            </Card>
          </Col>
          <Col
            xs={{ span: 24 }}
            md={{ span: 8 }}
            style={{ marginBottom: '5px' }}
          >
            <Card
              loading={loading}
              style={{
                width: '100%',
                maxHeight: '265px',
                borderRadius: '11px',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <SetError />
            </Card>
          </Col>
          <Col
            xs={{ span: 24 }}
            md={{ span: 8 }}
            style={{ marginBottom: '5px' }}
          >
            <Card
              loading={loading}
              style={{
                width: '100%',
                maxHeight: '265px',
                borderRadius: '11px',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <MultiSimulatorStart />
            </Card>
          </Col>
          <Col
            xs={{ span: 24 }}
            md={{ span: 8 }}
            style={{ marginBottom: '5px' }}
          >
            <Card
              loading={loading}
              style={{
                width: '100%',
                maxHeight: '265px',
                borderRadius: '11px',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <StopMultipleSimulator />
            </Card>
          </Col>

          {/* <Col
                xs={{ span: 24 }}
                md={{ span: 8 }}
                style={{ marginBottom: '5px' }}
              >
                <Card
                  loading={loading}
                  style={{
                    width: '100%',
                    maxHeight: '265px',
                    borderRadius: '11px',
                    textAlign: 'center',
                  }}
                >
                  <h5 style={{ fontWeight: '500' }}>Rezervasyon Yapma Oranı</h5>
                  <Space
                    wrap
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '1rem',
                    }}
                  >
                    <Progress
                      percent={data && data.reservationRate}
                      status="active"
                      strokeColor={{
                        from: '#108ee9',
                        to: '#87d068',
                      }}
                      type="circle"
                    />
                  </Space>
                </Card>
              </Col>
              <Col
                xs={{ span: 24 }}
                md={{ span: 8 }}
                style={{ marginBottom: '5px' }}
              >
                <Card
                  loading={loading}
                  style={{
                    width: '100%',
                    maxHeight: '265px',
                    borderRadius: '11px',
                    textAlign: 'center',
                  }}
                >
                  <h5 style={{ fontWeight: '500' }}>Aktif Şarj</h5>
                  <Space
                    wrap
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '1rem',
                    }}
                  >
                    <Statistic
                      value={data && data.activeCharge}
                      valueStyle={{
                        color:
                          data && data.activeCharge > 0 ? '#3f8600' : '#f5222d',
                        fontWeight: 'medium',
                        fontSize: '4.5rem',
                      }}
                      prefix={
                        <i className="fa-solid fa-arrow-up-wide-short"></i>
                      }
                    />
                  </Space>
                </Card>
              </Col>  */}
        </Row>
      </div>
    </div>
  );
};

export default Dashboard;
