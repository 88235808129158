import React, { useState } from 'react';
import DebouncedInput from '../DebouncedInput';
import { Col, Form, Modal, Row, message } from 'antd';
import api from '../../service';

const StopSimulatorModal = ({ id }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [data, setData] = useState({});

  const stop = async () => {
    if (data && (!data.deviceId || !data.deviceId))
      return message.error('Lütfen cihaz id girin !');

    setConfirmLoading(true);

    await api
      .post(`/rest/simulator/v1/stop`, data)
      .then(({ data }) => {
        if (data && data.result) {
          message.success('Simulator başarıyla durdurldu ...');
        } else {
          message.error('Simulator durdurulamadı !')
        }
      })
      .catch((err) => {
        console.log(err);
        message.error('Simulator Oluşturulamadı !!!');
      });

    setTimeout(() => {
      setIsModalOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };

  return (
    <div>
      <style>
        {`
          .ant-modal-footer > button:first-child {
              display: inline-block !important;
          }
        `}
      </style>
      <button
        type="button"
        className="btn btn-danger me-3 btn-lg"
        size={'large'}
        onClick={() => setIsModalOpen(true)}
      >
        <i className="fa-solid fa-charging-station"></i>
        Simulator Durdur
      </button>
      <Modal
        title="Simulator Durdurma Ekranı"
        open={isModalOpen}
        okText="Durdur"
        cancelText="Vazgeç"
        confirmLoading={confirmLoading}
        onOk={() => stop()}
        onCancel={() => setIsModalOpen(false)}
      >
        <Form
          layout="horizontal"
          size={'large'}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 22,
          }}
          onFinish={stop}
        >
          <Row direction="row">
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item
                label="Cihaz Id"
                name="deviceId"
                rules={[
                  {
                    required: true,
                    message: 'Lütfen cihaz id girin !',
                  },
                ]}
              >
                <DebouncedInput
                  delay={150}
                  name="deviceId"
                  record={data}
                  type="text"
                  setRecord={setData}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default StopSimulatorModal;
