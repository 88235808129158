import React, { useState } from 'react';
import DebouncedInput from '../DebouncedInput';
import { Col, Form, Modal, Row, message } from 'antd';
import api from '../../service';

const CreateCar = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const [data, setData] = useState({
      "batteryCapacity" : 90
    });
  
    const save = async () => {
      if (data && (!data.carId || !data.carId))
        return message.error('Lütfen araba id girin !');
  
      setConfirmLoading(true);
  
      await api
        .post(`/rest/simulator/v1/start-car`, data)
        .then(({ data }) => {
          if (data && data.result) {
            message.success('Simulator başarıyla oluşturuldu ...');
          } else {
            message.error('Simulator Oluşturulamadı');
          }
        })
        .catch((err) => {
          console.log(err);
          message.error('Simulator Oluşturulamadı !!!');
        });
  
      setTimeout(() => {
        setIsModalOpen(false);
        setConfirmLoading(false);
      }, 2000);
    };
  
    return (
      <div>
        <style>
          {`
            .ant-modal-footer > button:first-child {
                display: inline-block !important;
            }
          `}
        </style>
        <button
          type="button"
          className="btn btn-success me-3 btn-lg"
          size={'large'}
          onClick={() => setIsModalOpen(true)}
        >
          <i className="fa-solid fa-charging-station"></i>
          Araç Oluştur
        </button>
        <Modal
          title="Araç Başlat"
          open={isModalOpen}
          okText="Başlat"
          cancelText="Vazgeç"
          confirmLoading={confirmLoading}
          onOk={() => save()}
          onCancel={() => setIsModalOpen(false)}
        >
          <Form
            layout="horizontal"
            size={'large'}
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 22,
            }}
            onFinish={save}
          >
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Araç Id"
                  name="carId"
                  rules={[
                    {
                      required: true,
                      message: 'Lütfen cihaz id girin !',
                    },
                  ]}
                >
                  <DebouncedInput
                    delay={150}
                    name="carId"
                    record={data}
                    type="text"
                    setRecord={setData}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Cihaz Güç (kW) "
                  name="batteryCapacity"
                >
                  <DebouncedInput
                    delay={150}
                    name="batteryCapacity"
                    record={data}
                    type="number"
                    setRecord={setData}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    );
}

export default React.memo(CreateCar)